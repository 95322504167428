
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomStackedCardList',
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
  },
});
