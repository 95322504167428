
import Vue, { PropType } from 'vue';
import InlineSvg from 'vue-inline-svg';
import VueLottiePlayer from 'vue-lottie-player';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomInputText from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomAvatar, { AvatarSize } from '@/components/atoms/AtomAvatar.vue';
import AtomChip from '@/components/atoms/AtomChip.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import MoleculeModal, { ModalSize } from '@/components/molecules/modal/MoleculeModal.vue';
import { RideUserModel } from '@/models/ride/RideModel';
import { getImageUrl } from '@/services/assets/AssetsService';

enum AnnotationsEnum {
  CLEANLINESS = 'cleanliness',
  COMMUNICATION = 'communication',
  CONSIDERATION = 'consideration',
  DRIVING_STYLE = 'drivingstyle',
  NAVIGATION = 'navigation',
  PUNCTUALITY = 'punctuality',
}

export default Vue.extend({
  name: 'OrganismUsersRatingModal',
  components: {
    AtomInputText,
    AtomText,
    AtomAvatar,
    AtomButton,
    AtomChip,
    AtomActionText,
    AtomSvgIcon,
    MoleculeModal,
    InlineSvg,
    VueLottiePlayer,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    matchId: {
      type: String,
      required: true,
    },
    users: {
      type: Array as PropType<RideUserModel[]>,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      rating: 0,
      isOverlayOpened: false,
      ModalSize,
      AvatarSize,
      ButtonVariant,
      selectedAnnotations: [] as AnnotationsEnum[],
      annotations: [
        {
          icon: getImageUrl('icons/icon-hand-drop'),
          annotation: AnnotationsEnum.CLEANLINESS,
        },
        {
          icon: getImageUrl('icons/icon-message-conversation'),
          annotation: AnnotationsEnum.COMMUNICATION,
        },
        {
          icon: getImageUrl('icons/icon-user-check'),
          annotation: AnnotationsEnum.CONSIDERATION,
        },
        {
          icon: getImageUrl('icons/icon-car-front-1'),
          annotation: AnnotationsEnum.DRIVING_STYLE,
        },
        {
          icon: getImageUrl('icons/icon-map'),
          annotation: AnnotationsEnum.NAVIGATION,
        },
        {
          icon: getImageUrl('icons/icon-clock'),
          annotation: AnnotationsEnum.PUNCTUALITY,
        },
      ],
      suggestedAnnotations: [''] as string[],
    };
  },
  computed: {
    userToRate(): RideUserModel|null {
      return this.users.length > 0 ? this.users[0] : null;
    },
    userName(): string {
      return this.userToRate ? `${this.userToRate.firstName} ${this.userToRate.lastName}` : '';
    },
  },
  methods: {
    resetState(): void {
      this.rating = 0;
    },
    setRating(rating: number): void {
      this.rating = rating;
    },
    getImageUrl,
    toggleAnnotations(annotation: AnnotationsEnum, annotations: AnnotationsEnum[]): AnnotationsEnum[] {
      if (annotations.includes(annotation)) {
        return [...annotations].filter((a) => a !== annotation);
      }

      return [...annotations, annotation];
    },
    removeSuggestedAnnotation(index: number) {
      this.suggestedAnnotations = this.suggestedAnnotations.filter((_v, i) => i !== index);
    },
    setNewAnnotation(value: string, index: number) {
      const { suggestedAnnotations } = this;
      suggestedAnnotations[index] = value;
      this.suggestedAnnotations = [...suggestedAnnotations];
    },
  },
  watch: {
    users() {
      this.resetState();
    },
    rating() {
      this.selectedAnnotations = [];
    },
  },
});
