import moment from 'moment';
import i18n from '@/i18n';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import FeaturesEnum from '@/enums/settings/FeatureEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import { hasFeature } from '@/services/feature/FeatureService';

export const showParkingInformation = (role: RolesEnum, features: string[], providesParkingPlace: boolean): boolean => (
  hasFeature(features, FeaturesEnum.PARKING_OPTIONS)
    && providesParkingPlace
    && role !== RolesEnum.PASSENGER
);

export const getParkingIcon = (intent: RideIntentModel, currTime: moment.Moment): string => {
  if (
    moment(intent.earliestDeparture).diff(currTime, 'minutes', false) < 60
    && !intent.parkingSpace
  ) return 'icons/icon-parking-disabled';

  if (intent.parkingSpace) {
    return 'icons/icon-parking';
  }

  return 'icons/icon-parking-search';
};

export const getParkingStatus = (intent: RideIntentModel, currTime: moment.Moment): string => {
  if (
    moment(intent.earliestDeparture).diff(currTime, 'minutes', false) < 60
    && !intent.parkingSpace
  ) {
    return i18n.t('parkingStatus.notFound').toString();
  }
  if (intent.parkingSpace) {
    return `Nr. ${intent.parkingSpace.label}, ${intent.parkingSpace.location}`;
  }

  return i18n.t('parkingStatus.requested').toString();
};
